import React from 'react'

import logo from '../img/logo-white.svg'
import instagram from '../img/social/instagram-white.svg'
import UniversalLink from "../components/UniversalLink"

const config = require('../../config/site')

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer">
        <div className="content centered">
          <img
            src={logo}
            alt={config.company}
          />
          <p className="footer-copyright">
            © {new Date().getFullYear()}{" "}
            <UniversalLink to={"/"}>{config.company}</UniversalLink>
          </p>
          <div className="social">
            <UniversalLink
              to={"https://instagram.com/"+config.instagram}
              title="instagram"
            >
              <img
                src={instagram}
                alt="Instagram"
              />
            </UniversalLink>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
