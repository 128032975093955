module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  title: 'Laurie\'s Cafe', // Navigation and Site Title
  titleAlt: 'Laurie\'s Cafe', // Title for JSONLD
  description: 'Laurie\'s Cafe',
  url: 'https://lauriescafe.com', // Domain of your site. No trailing slash!
  siteUrl: 'https://lauriescafe.com', // url + pathPrefix
  blogUri: '',
  siteLanguage: 'en', // Language Tag on <html> element
  logo: 'src/img/logo-black.png', // Used for SEO
  banner: 'src/img/og-image.jpg',
  // JSONLD / Manifest
  favicon: 'src/img/favicon.png', // Used for manifest favicon generation
  shortName: 'lauries', // shortname for manifest. MUST be shorter than 12 characters
  author: 'David Sapp', // Author for schemaORGJSONLD
  themeColor: '#ffffff',
  backgroundColor: '#ffffff',
  // SOCIAL MEDIA
  instagram: 'lauriescafegeorgetown',
  twitter: '', // Twitter Username
  // EXTRA
  hoursOfOperation: 'Mon - Sat: 11am - 3pm',
  address: '612 Main St, Georgetown, TX',
  phone: '512-863-5875',
  email: 'lauriescafe.gtown@gmail.com',
  // FOOTER
  company: 'Laurie\'s Cafe',
};
